@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.experience-card-col .experience-card-gap {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}

.experience-card-col {
  font-family: "Poppins", sans-serif !important;
}

.experience-card-description {
  text-align: left;
  padding-inline-start: 20px !important;
  list-style: "⚡";
}

.experience-card {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  text-align: center;
  border: none;
}

@media (max-width: 600px) {
  .experience-card {
    height: 580px;
  }
}

.experience-card-body p {
  margin: 0px;
  font-size: 14px;
}

.experience-card-1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: none;
  border-radius: 13px;
  contain: content;
  margin: 4em auto 36px;
  box-shadow: 0px 8px #bc344e, 0 6px 20px 0 rgb(0 0 0 / 19%),
    0 6px 20px 0 rgb(0 0 0 / 19%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.experience-card-1:hover {
  border-radius: 13px;
  z-index: 0;
  cursor: pointer;
}

.experience-card-body .pre-heading {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
}

.experience-card-body .meta {
  font-size: 16px;
}

.experience-card-body .author {
  font-size: 12px;
}

.experience-card-body h3 {
  font-size: 2rem;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
}

.main ul,
.main1 ul {
  display: block;
  margin: 0 auto;
  max-width: 1050px;
  padding: 1rem !important;
}

.main a,
.main a {
  text-decoration: none;
}

.experience-card-body::before {
  content: "";
  position: absolute;
  z-index: -5;
  right: -16px;
  bottom: 10px;
  height: 30%;
  width: 4.55%;
  background: #e3405f;
  border-radius: 32px;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.experience-card-body:hover:before {
  transform: scale(45);
}

.experience-card-body:hover * {
  transition-property: color;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 1.05s;
  color: #ffffff;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.experience-card-body {
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
  background: #333;
  padding: 10px;
}

.experience-card-body:hover {
  transform: scale(1);
}

.experience-card-1 .experience-card-body {
  display: flex;
  flex-flow: row wrap;
  padding: 18px;
  width: 100%;
}

.experience-card-1 header {
  flex: 100%;
}

.experience-card-1 .meta {
  margin-bottom: 22px;
  margin: 0;
  text-align: center;
}

ul ol {
  list-style: disc;
  padding: 3%;
}

.experience-company-logo {
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.experience-card-1 .featured-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  width: 300px;
}

@media only screen and (min-width: 1280px) {
  .experience-card-1 h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .experience-card-1 {
    flex-direction: row;
  }

  .experience-card-1 h3 {
    font-size: calc(100% + 1vw);
  }

  .experience-card-1 .featured-image {
    height: auto;
    width: 300px;
  }

  .experience-card-1 .experience-company-logo {
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    border-top-right-radius: 0;
    height: auto;
    width: 300px;
  }
}

@media only screen and (max-width: 992px) {
  .experience-card-1 .featured-image {
    width: 300px;
    height: auto;
  }

  .experience-card-1 .experience-company-logo {
    height: 100%;
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .experience-card-1 .featured-image {
    height: auto;
  }

  .experience-card-body::before {
    height: 4.33%;
    width: 40%;
  }

  .experience-card-1 .experience-company-logo {
    height: auto;
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) {
  .experience-card-body::before {
    width: 4.45%;
  }
}

@media only screen and (min-width: 1441px) {
  .experience-card-body::before {
    width: 4.43%;
  }
}

@media only screen and (min-width: 1600px) {
  .experience-card-body::before {
    width: 4.41%;
  }
}

@media only screen and (min-width: 2000px) {
  .experience-card-body::before {
    width: 4.39%;
  }
}

@media only screen and (min-width: 2560px) {
  .experience-card-body::before {
    width: 4.385%;
  }
}

@media only screen and (max-width: 401px) {
  .experience-card-1 .featured-image {
    height: auto;
    width: 100%;
  }

  .experience-card-1 .experience-company-logo {
    height: auto;
    width: 100%;
  }
}
